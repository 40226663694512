import tippy from 'tippy.js';
import { hideAll } from 'tippy.js';
import Vue from 'vue';
import PasstypeRestrictionDates from '../../components/passtype-restriction-dates/PasstypeRestrictionDates.vue';



const pageHasEntryRestrictionDatesMap = document.querySelector("#EntryRestrictionDatesMap") != null;

if (pageHasEntryRestrictionDatesMap) {

    //const myComponent = Vue.component('my-component', {
    //    props: {
    //        passTypeId: {
    //            type: Number,
    //            default: 1,
    //            required: false
    //        }
    //    },
    //    components: {
    //        'passtype-restrictions-dates': PasstypeRestrictionDates
    //    },
    //    template: `
    //        <div>
    //            <passtype-restrictions-dates v-bind="$props" />
    //        </div>`
    //});

    document.querySelectorAll(".passholder-exclusion-dates-page-wrapper").forEach(function (element) {
        new Vue({
            el: element,
            components: {
                'passtype-restrictions-dates': PasstypeRestrictionDates
            }
        });
    })
    

}