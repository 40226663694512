window.addEventListener("DOMContentLoaded",
    function(e) {
        $(".umbraco-forms-merlin form").on('submit',
            function(e) {
                var formName = $(this).parent().data('form-name');
                if (formName !== 'undefined') {
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({ "event": 'forms-'+formName });
                }
            });
    });